import React, { createContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);
  const [pkCredentialMasterId, setPkCredentialMasterId] = useState(null);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiryTime");
    setToken(null);
    setUser(null);
    navigate("/sign-in");
    toast.success("Session expired, logged out!", {
      autoClose: 1000,
      position: "bottom-right",
    });
  }, [navigate]);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (tokenExpiryTime) {
      const currentTime = new Date().getTime();
      if (currentTime > parseInt(tokenExpiryTime)) {
        handleLogout();
      } else {
        const remainingTime = parseInt(tokenExpiryTime) - currentTime;
        setTimeout(handleLogout, remainingTime);
      }
    }
    setLoading(false); // Set loading to false after checking local storage
  }, [handleLogout]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      // const expiryTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
      // const expiryTime = new Date().getTime() + 5 * 60 * 60 * 1000; // 5 hour from now
      const expiryTime = new Date().getTime() + (2 * 60 * 60 * 1000) + (40 * 60 * 1000); // 4 hour from now
      // const expiryTime = new Date().getTime() + (4 * 60 * 60 * 1000) + (55 * 60 * 1000); // 4 hours and 55 minutes from now
      // const expiryTime = new Date().getTime() + 1 * 60 * 1000; // 5 hour from now
      localStorage.setItem("tokenExpiryTime", expiryTime);
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiryTime");
    }
  }, [token]);

  // const signUp = async (userId, password, name) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/register/add`,
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           userId,
  //           userPass: password,
  //           name: name,
  //         }),
  //       }
  //     );
  //     const data = await response.json();
  //     console.log("sign up data response", data);
  //     if (data.status === "success") {
  //       localStorage.setItem("user", JSON.stringify(data.user));
  //       setUser(data.user);
  //       navigate("/sign-in");
  //       toast.success("Sign Up Successfully!", {
  //         autoClose: 1000,
  //         position: "bottom-right",
  //       });
  //     } else {
  //       toast.error("Sign Up Failed!", {
  //         autoClose: 1000,
  //         position: "bottom-right",
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Error for Sign Up API:", error, {
  //       autoClose: 1000,
  //       position: "bottom-right",
  //     });
  //   }
  // };
  const signUp = async (userId, password, name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/register/add`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            userPass: password,
            name: name,
          }),
        }
      );
      const data = await response.json();

      // Check if the status code is 201, meaning the user was created successfully
      if (response.status === 201) {
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        navigate("/sign-in");
        toast.success(data.message || "Sign Up Successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      } else {
        // Show an error message from the API if available
        toast.error("Sign Up failed!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      // Show a generic error message in case of an exception
      toast.error(`Error during Sign Up: ${error.message}`, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const signIn = async (userId, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          password: password,
          key: "lTNM7FgDWBXqti4E",
        }),
      });
      const data = await response.json();
      if (data.status === "Successful") {
        const { token } = data.data[0];
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(data.data[0]));
        setToken(token);
        setUser(data.data[0]);
        navigate("/");
        toast.success("Sign In Successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      } else {
        toast.error(data.message, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Error for Sign In API:", error, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const signOut = () => {
    handleLogout();
  };

  const sendEmailLink = async (userId) => {
    try {
      // Step 1: Fetch data to get pkCredentialMasterId
      const searchResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/getUserDetail`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId }),
        }
      );
      const searchData = await searchResponse.json();

      // Check if search was successful
      if (!searchResponse.ok) {
        throw new Error(
          searchData.message || "Failed to retrieve data for password reset."
        );
      }

      // Extract pkCredentialMasterId from getUserDetail
      const { pkCredentialMasterId } = searchData;

      // Step 2: Send reset password link with pkCredentialMasterId
      const resetUrl = `https://admin.karvaccessories.com/forgot-password?pkCredentialMasterId=${pkCredentialMasterId}`;
      const resetResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId, url: resetUrl }),
        }
      );
      const resetData = await resetResponse.json();

      // Check if reset request was successful
      if (resetResponse.ok) {
        toast.success("Password reset link sent to email.", {
          autoClose: 1000,
          position: "bottom-right",
        });
      } else {
        throw new Error(resetData.message || "Failed to send reset link.");
      }
    } catch (error) {
      toast.error("Error for Forgot Password API:", error.message, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  const forgotPassword = async (pkCredentialMasterId, password) => {
    try {
      if (!pkCredentialMasterId) {
        throw new Error("pkCredentialMasterId is not set");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/register/update/${pkCredentialMasterId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userPass: password }),
        }
      );

      const data = await response.json();
      if (data.success) {
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        navigate("/sign-in");
      }
    } catch (error) {
      toast.error("Error for Update User API:", error.message, {
        autoClose: 1000,
        position: "bottom-right",
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signUp,
        signIn,
        signOut,
        token,
        loading,
        sendEmailLink,
        forgotPassword,
        pkCredentialMasterId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

// import React, { createContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const navigate = useNavigate();
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(localStorage.getItem("token"));
//   const [loading, setLoading] = useState(true);
//   const [pkCredentialMasterId, setPkCredentialMasterId] = useState(null);

//   useEffect(() => {
//     // Check if user is logged in on initial load
//     const storedUser = localStorage.getItem("user");
//     if (storedUser) {
//       setUser(JSON.parse(storedUser));
//     }

//     // // Check and handle token expiration
//     // const handleTokenExpiration = () => {
//     //   const storedTimeStamp = localStorage.getItem("timeStamp");
//     //   if (storedTimeStamp) {
//     //     const loginTime = new Date(storedTimeStamp);
//     //     const expirationTime = new Date(loginTime.getTime() + 45 * 60 * 1000);
//     //     const currentTime = new Date();

//     //     if (currentTime >= expirationTime) {
//     //       signOut();
//     //       toast.info("Session expired. Please sign in again.",{
//     //         autoClose: 1000,
//     //         position: 'bottom-right'
//     //       });
//     //     } else {
//     //       setTimeout(() => {
//     //         signOut();
//     //         toast.info("Session expired. Please sign in again.",{
//     //           autoClose: 1000,
//     //           position: 'bottom-right'
//     //         });
//     //       }, expirationTime - currentTime);
//     //     }
//     //   }
//     // };

//     // handleTokenExpiration();
//     setLoading(false); // Set loading to false after checking local storage
//   }, []);

//   useEffect(() => {
//     if (token) {
//       localStorage.setItem("token", token);
//     } else {
//       localStorage.removeItem("token");
//     }
//   }, [token]);

//   const signUp = async (userId, password) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/register/add`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({
//             userId,
//             userPass: password,
//           }),
//         }
//       );
//       const data = await response.json();
//       if (data.success) {
//         localStorage.setItem("user", JSON.stringify(data.user));
//         setUser(data.user);
//         navigate("/sign-in");
//         toast.success("Sign Up Successfully!",{
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       } else {
//         toast.error(data.message,{
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       }
//     } catch (error) {
//       toast.error("Error for Sign Up API:", error,{
//         autoClose: 1000,
//         position: 'bottom-right'
//       });
//     }
//   };

//   const signIn = async (userName, password) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//           userName,
//           password: password,
//           key: "lTNM7FgDWBXqti4E",
//         }),
//       });
//       const data = await response.json();
//       if (data.status === "Successful") {
//         const { token } = data.data[0];
//         const timeStamp = data.timeStamp.split(".")[0]; // Get timestamp without milliseconds
//         localStorage.setItem("token", token);
//         localStorage.setItem("user", JSON.stringify(data.data[0]));
//         localStorage.setItem("timeStamp", timeStamp);
//         setToken(token);
//         setUser(data.data[0]);
//         navigate("/");
//         toast.success("Sign In Successfully!",{
//           autoClose: 1000,
//           position: 'bottom-right'
//         });

//         const expirationTime = new Date(
//           new Date(timeStamp).getTime() + 45 * 60 * 1000
//         );
//         // setTimeout(() => {
//         //   signOut();
//         //   toast.info("Session expired. Please sign in again.",{
//         //     autoClose: 1000,
//         //     position: 'bottom-right'
//         //   });
//         // }, expirationTime - new Date());
//       } else {
//         toast.error(data.message,{
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       }
//     } catch (error) {
//       toast.error("Error for Sign In API:", error,{
//         autoClose: 1000,
//         position: 'bottom-right'
//       });
//     }
//   };

//   const signOut = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user");
//     localStorage.removeItem("timeStamp");
//     setToken(null);
//     setUser(null);
//     navigate("/sign-in");
//     toast.success("Log Out!",{
//       autoClose: 1000,
//       position: 'bottom-right'
//     });
//   };

//   const sendEmailLink = async (userName) => {
//     try {
//       // Step 1: Fetch data to get pkCredentialMasterId
//       const searchResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/getUserDetail`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ userName }),
//         }
//       );
//       const searchData = await searchResponse.json();

//       // Check if search was successful
//       if (!searchResponse.ok) {
//         throw new Error(
//           searchData.message || "Failed to retrieve data for password reset."
//         );
//       }

//       // Extract pkCredentialMasterId from getUserDetail
//       const { pkCredentialMasterId } = searchData;

//       // Step 2: Send reset password link with pkCredentialMasterId
//       const resetUrl = `https://admin.karvaccessories.com/forgot-password?pkCredentialMasterId=${pkCredentialMasterId}`;
//       const resetResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/forgot-password`,
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ userName, url: resetUrl }),
//         }
//       );
//       const resetData = await resetResponse.json();

//       // Check if reset request was successful
//       if (resetResponse.ok) {
//         toast.success("Password reset link sent to email.",{
//           autoClose: 1000,
//           position: 'bottom-right'
//         });
//       } else {
//         throw new Error(resetData.message || "Failed to send reset link.");
//       }
//     } catch (error) {
//       toast.error("Error for Forgot Password API:", error.message,{
//         autoClose: 1000,
//         position: 'bottom-right'
//       });
//     }
//   };

//   const forgotPassword = async (pkCredentialMasterId, password) => {
//     try {
//       if (!pkCredentialMasterId) {
//         throw new Error("pkCredentialMasterId is not set");
//       }

//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/register/update/${pkCredentialMasterId}`,
//         {
//           method: "PUT",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify({ userPass: password }),
//         }
//       );

//       const data = await response.json();
//       if (data.success) {
//         localStorage.setItem("user", JSON.stringify(data.user));
//         setUser(data.user);
//         navigate("/sign-in");
//       }
//     } catch (error) {
//       toast.error("Error for Update User API:", error.message,{
//         autoClose: 1000,
//         position: 'bottom-right'
//       });
//     }
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         user,
//         signUp,
//         signIn,
//         signOut,
//         token,
//         loading,
//         sendEmailLink,
//         forgotPassword,
//         pkCredentialMasterId,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export { AuthContext, AuthProvider };
