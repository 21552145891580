import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { ProductContext } from "../../contexts/ProductContext";
import axios from "axios";
import { toast } from "react-toastify";

const Products = () => {
  const {
    products,
    totalPages,
    currentPage,
    updateCurrentPage,
    pageSize,
    handlePageSizeChange,
    loading,
    fetchData,
  } = useContext(ProductContext);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (!loading) {
      setFilteredProducts(products);
    }
  }, [loading, products]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (!loading) {
      const results = products.filter((product) =>
        product.prodName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);
    }
  }, [searchTerm, products, loading]);

  const handlePageChange = (page) => {
    updateCurrentPage(page);
  };

  const renderFirstImage = (images) => {
    if (images.length > 0) {
      return <img src={images[0].imagePath[0]} alt="Product" />;
    }
    return <span>No Image Available</span>;
  };

  const handleDeleteProduct = async (cartId) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/product/delete/${cartId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchData();
        navigate("/product");
        toast.success("Deleted!",{
          autoClose: 1000,
          position: 'bottom-right'
        });
      } catch (error) {
        console.error("Delete error:", error);
      }
    }
  };

  return (
    <>
      <section className="product-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="product-page-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h2>Products</h2>
              </div>
              <div className="product-heading d-flex align-items-center justify-content-center">
                <form action="">
                  <div className="search-box-input">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
                <Link
                  to={"/add-product"}
                  className="product-page-heading-add-link fs-6"
                >
                  <FaPlus className="plus-icon-add" />
                  <span>Add</span>
                </Link>
              </div>
            </div>
            {filteredProducts.length > 0 ? (
              <>
                <div className="product-page-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Product Category</th>
                        <th scope="col">Price</th>
                        {/* <th scope="col">Description</th> */}
                        <th scope="col">Image</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredProducts.map((product, index) => (
                        <tr key={product.pkProdId}>
                          <th scope="row">
                            {(currentPage - 1) * pageSize + index + 1}
                          </th>
                          <td>{product.prodName}</td>
                          <td>{product.prodCategory}</td>
                          <td>{product.prodPrice}</td>
                          {/* <td>
                            {product.prodDesc}
                          </td> */}
                          <td>{renderFirstImage(product.info)}</td>
                          <td>
                            <Link
                              to={`/edit-product/${product.pkProdId}`}
                              className="action-icon-edit-link"
                            >
                              <FaEdit />
                            </Link>
                            <button
                              className="action-icon-delete-btn"
                              onClick={() =>
                                handleDeleteProduct(product.pkProdId)
                              }
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination-controls">
                    <label>
                      Show
                      <select value={pageSize} onChange={handlePageSizeChange}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                      Products per Page
                    </label>
                    <div className="pagination-buttons">
                      <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage >= totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="loading-data">
                  <p>No products found.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
