import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

const SignUpNew = () => {
  const { signUp } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    userId: "",
    name: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");

    if (email) {
      setUserId(email);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    validateField("name", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateField("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateField("confirmPassword", e.target.value);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    switch (fieldName) {
      case "name":
        if (!value.trim()) {
          errorMessage = "Name is required";
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          errorMessage = "Name should only contain letters and spaces";
        } else if (value.length < 2 || value.length > 50) {
          errorMessage = "Name should be between 2 and 50 characters";
        } else {
          errorMessage = "";
        }
        break;
      case "password":
        errorMessage =
          value.length >= 6 ? "" : "Password must be at least 6 characters";
        break;
      case "confirmPassword":
        errorMessage = value === password ? "" : "Passwords do not match";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await signUp(userId, password, name);
        navigate("/sign-in");
      } catch (error) {
        toast.error(`Sign-Up Failed: ${error.message}`, {
          autoClose: 1000,
          position: "bottom-right",
        });
        navigate("/sign-up");
      }
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (password.length < 6) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters",
      }));
      isValid = false;
    }

    if (confirmPassword !== password) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <section className="sign-up-page">
        <div className="sign-up-page-block">
          <div className="our-container px-3">
            <div className="inner-container mx-auto">
              <div className="sign-up-page-body">
                <div className="">
                  <div className="mb-2 sign-up-page-heading">
                    <h3 className="fs-2 fw-medium text-center text-capitalize">
                      Sign Up
                    </h3>
                  </div>
                  <div className="mx-auto sign-up-page-form">
                    <form onSubmit={handleSignUp} className="pt-2 pb-2">
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label
                            htmlFor="email"
                            className="text-capitalize mb-1"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            autoComplete="off"
                            value={userId}
                            readOnly
                            className="px-2 rounded-0"
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label
                            htmlFor="name"
                            className="text-capitalize mb-1"
                          >
                            Name
                          </label>
                          <div className="position-relative sign-up-name-group">
                            <input
                              type="text"
                              id="name"
                              autoComplete="off"
                              value={name}
                              onChange={handleNameChange}
                              className={`px-2 rounded-0 ${
                                formErrors.name ? "is-invalid" : ""
                              }`}
                            />
                          </div>
                          {formErrors.name && (
                            <p className="text-danger">{formErrors.name}</p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label
                            htmlFor="password"
                            className="text-capitalize mb-1"
                          >
                            Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="off"
                              value={password}
                              onChange={handlePasswordChange}
                              className={`px-2 rounded-0 ${
                                formErrors.password ? "is-invalid" : ""
                              }`}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.password && (
                            <p className="text-danger">{formErrors.password}</p>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column mb-4 sign-up-form-group">
                          <label
                            htmlFor="confirm-password"
                            className="text-capitalize mb-1"
                          >
                            Confirm Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              id="confirm-password"
                              autoComplete="off"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              className={`px-2 rounded-0 ${
                                formErrors.confirmPassword ? "is-invalid" : ""
                              }`}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.confirmPassword && (
                            <p className="text-danger">
                              {formErrors.confirmPassword}
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="border-0 fw-semibold text-capitalize sign-up-form-button"
                      >
                        Sign Up
                      </button>
                      <p className="fs-6 mb-0 mt-2 text-end sign-up-register-para">
                        Returning Customer {"->"}{" "}
                        <Link
                          to={"/sign-in"}
                          className="fw-bold sign-up-register-link"
                        >
                          Sign In Now
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpNew;
