import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { forgotPassword } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [pkCredentialMasterId, setPkCredentialMasterId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pkCredentialMasterIdUrl = params.get("pkCredentialMasterId");

    if (pkCredentialMasterIdUrl) {
      setPkCredentialMasterId(pkCredentialMasterIdUrl);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validateField("password", e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validateField("confirmPassword", e.target.value);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    switch (fieldName) {
      case "password":
        errorMessage =
          value.length >= 6 ? "" : "Password must be at least 6 characters";
        break;
      case "confirmPassword":
        errorMessage = value === password ? "" : "Passwords do not match";
        break;
      default:
        break;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = () => {
    validateField("password", password);
    validateField("confirmPassword", confirmPassword);

    return !formErrors.password && !formErrors.confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await forgotPassword(pkCredentialMasterId, password);
        navigate("/sign-in");
        toast.success("Password updated successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });
      } catch (error) {
        toast.error("Failed to update password:", error, {
          autoClose: 1000,
          position: "bottom-right",
        });
      }
    }
  };

  console.log("pkCredentialMasterId ", pkCredentialMasterId);

  return (
    <>
      <section className="sign-up-page">
        <div className="sign-up-page-block">
          <div className="our-container px-3">
            <div className="inner-container mx-auto">
              <div className="sign-up-page-body">
                <div className="">
                  <div className="mb-2 sign-up-page-heading">
                    <h3 className="fs-2 fw-medium text-center text-capitalize">
                      Forgot Password
                    </h3>
                  </div>
                  <div className="mx-auto sign-up-page-form">
                    <form className="pt-2 pb-2" onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column sign-up-form-group">
                          <label
                            htmlFor="password"
                            className="text-capitalize mb-1"
                          >
                            Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              id="password"
                              autoComplete="off"
                              value={password}
                              onChange={handlePasswordChange}
                              className={`px-2 rounded-0 ${
                                formErrors.password ? "is-invalid" : ""
                              }`}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={togglePasswordVisibility}
                            >
                              {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.password && (
                            <div className="invalid-feedback">
                              {formErrors.password}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="d-flex align-align-items-center flex-column mb-4 sign-up-form-group">
                          <label
                            htmlFor="confirm-password"
                            className="text-capitalize mb-1"
                          >
                            Confirm Password
                          </label>
                          <div className="position-relative sign-up-password-group">
                            <input
                              type={showConfirmPassword ? "text" : "password"}
                              id="confirm-password"
                              autoComplete="off"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              className={`px-2 rounded-0 ${
                                formErrors.confirmPassword ? "is-invalid" : ""
                              }`}
                            />
                            <button
                              type="button"
                              className="position-absolute top-0 end-0 password-toggle-icon"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                          </div>
                          {formErrors.confirmPassword && (
                            <div className="invalid-feedback">
                              {formErrors.confirmPassword}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="border-0 fw-semibold text-capitalize sign-up-form-button"
                      >
                        Update Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
