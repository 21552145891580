import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEye } from "react-icons/fa";

const Orders = () => {
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useState({
    orderId: "",
    paymentId: "",
    clientEmail: "",
    orderStatus: "",
  });
  // const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchOrderStatusList = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setOrderStatusList(data);
        } else {
          console.error("Failed to fetch order status list");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchOrderStatusList();
  }, []);

  useEffect(() => {
    // if (isSearchClicked) {
    fetchOrders();
    // }
  }, [currentPage, pageSize]);

  const fetchOrders = async (isSearch = false) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/search`,
        {
          // pkOrdID: "",
          // clientName: "",
          // clientEmail: searchParams.clientEmail,
          // clientPhNo: "",
          // clientAdd: "",
          // orderStatus: searchParams.orderStatus,
          // orderId: searchParams.orderId,
          // paymentId: searchParams.paymentId,
          // pageSize: pageSize,
          // pageNo: currentPage,
          pkOrdID: "",
          clientName: "",
          clientEmail: isSearch ? searchParams.clientEmail : "",
          clientPhNo: "",
          clientAdd: "",
          orderStatus: isSearch ? searchParams.orderStatus : "",
          orderId: isSearch ? searchParams.orderId : "",
          paymentId: isSearch ? searchParams.paymentId : "",
          pageSize: pageSize,
          pageNo: currentPage,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.status === "Successful") {
        setOrders(response.data.orderData);
        setTotalPages(Math.ceil(response.data.totalResult / pageSize));
        setError(null);
      } else {
        setOrders([]);
        setTotalPages(0);
        setError("No orders found");
      }
    } catch (error) {
      setError("Error fetching orders");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // setIsSearchClicked(true);
    setCurrentPage(1); // Reset to first page on search
    fetchOrders(true);
  };

  const handleViewOrder = (orderId) => {
    const order = orders.find((order) => order.pkorderId === orderId);
    if (order) {
      setSelectedOrder(order);
    }
  };

  if (loading) {
    return (
      <div className="loading-data">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <section className="order-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="order-heading mb-4">
              <h2>Orders</h2>
            </div>

            {/* <div className="order-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h2>Orders</h2>
              </div>
              <div className="order-heading d-flex align-items-center justify-content-center">
                <form action="">
                  <div className="search-box-input">
                    <input type="text" placeholder="Search..." />
                  </div>
                </form>
              </div>
            </div> */}

            <div className="order-page-heading mb-4 d-flex align-items-center justify-content-end mx-3">
              <form onSubmit={handleSearchSubmit}>
                <div className="grid-block">
                  <div className="grid-b-item">
                    <input
                      type="text"
                      placeholder="Order Id"
                      name="orderId"
                      value={searchParams.orderId}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="grid-b-item">
                    <input
                      type="text"
                      placeholder="Payment Id"
                      name="paymentId"
                      value={searchParams.paymentId}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="grid-b-item">
                    <input
                      type="text"
                      placeholder="Client Email"
                      name="clientEmail"
                      value={searchParams.clientEmail}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    />
                  </div>
                  <div className="grid-b-item">
                    <select
                      name="orderStatus"
                      value={searchParams.orderStatus}
                      onChange={handleSearchChange}
                      autoComplete="off"
                    >
                      <option value="">Select Status</option>
                      {orderStatusList.option &&
                        orderStatusList.option.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary order-page-btn fs-6"
                >
                  Search
                </button>
              </form>
            </div>
            {/* Display orders or no orders found */}
            {orders.length > 0 ? (
              <div className="order-page-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Client Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Order Amount</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => {
                      // Calculate the total quantity from subOrderDetail
                      const totalQuantity = order.orderDetail.reduce(
                        (sum, detail) => {
                          return (
                            sum +
                            detail.subOrderDetail.reduce(
                              (subSum, subDetail) =>
                                subSum + subDetail.quantity,
                              0
                            )
                          );
                        },
                        0
                      );

                      return (
                        <tr key={order.pkorderId}>
                          <th scope="row">
                            {index + 1 + (currentPage - 1) * pageSize}
                          </th>
                          <td>{order.clientName}</td>
                          <td>{order.clientEmail}</td>
                          <td>{order.clientPhNo1 || order.clientPhNo2}</td>
                          <td>{order.clientAdd1 || order.clientAdd2}</td>
                          <td>{totalQuantity}</td>
                          <td>{order.orderStatus}</td>
                          <td>{order.orderAmount}</td>
                          <td>{order.orderId}</td>
                          <td>{order.paymentId || "N/A"}</td>
                          <td>
                            <button
                              type="button"
                              className="view-order-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#viewOrderModal"
                              onClick={() => handleViewOrder(order.pkorderId)}
                            >
                              <FaEye />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    {/* {orders.map((order, index) => (
                      <tr key={order.pkorderId}>
                        <th scope="row">
                          {index + 1 + (currentPage - 1) * pageSize}
                        </th>
                        <td>{order.clientName}</td>
                        <td>{order.clientEmail}</td>
                        <td>{order.clientPhNo1 || order.clientPhNo2}</td>
                        <td>{order.clientAdd1 || order.clientAdd2}</td>
                        <td>quantity</td>
                        <td>{order.orderStatus}</td>
                        <td>{order.orderAmount}</td>
                        <td>{order.orderId}</td>
                        <td>{order.paymentId || "N/A"}</td>
                        <td>
                          <button
                            type="button"
                            className="view-order-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#viewOrderModal"
                            onClick={() => handleViewOrder(order.pkorderId)}
                          >
                            <FaEye />
                          </button>
                        </td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
                <div className="pagination-controls">
                  <label>
                    Show
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                    </select>
                    Orders per Page
                  </label>
                  <div className="pagination-buttons">
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="loading-data">
                <p>No Orders found.</p>
              </div>
            )}
            {/* {isSearchClicked ? (
              orders.length > 0 ? (
                <div className="order-page-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Address</th>
                        <th scope="col">Order Status</th>
                        <th scope="col">Order Amount</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">Payment ID</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((order, index) => (
                        <tr key={order.pkorderId}>
                          <th scope="row">
                            {index + 1 + (currentPage - 1) * pageSize}
                          </th>
                          <td>{order.clientName}</td>
                          <td>{order.clientEmail}</td>
                          <td>{order.clientPhNo1 || order.clientPhNo2}</td>
                          <td>{order.clientAdd1 || order.clientAdd2}</td>
                          <td>{order.orderStatus}</td>
                          <td>{order.orderAmount}</td>
                          <td>{order.orderId}</td>
                          <td>{order.paymentId || "N/A"}</td>
                          <td>
                            <button
                              type="button"
                              className="view-order-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#viewOrderModal"
                              onClick={() => handleViewOrder(order.pkorderId)}
                            >
                              <FaEye />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination-controls">
                    <label>
                      Show
                      <select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                      </select>
                      Orders per Page
                    </label>
                    <div className="pagination-buttons">
                      <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage >= totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="loading-data">
                  <p>No Orders found.</p>
                </div>
              )
            ) : null} */}
            {/* {isSearchClicked && orders.length > 0 ? (
              <div className="order-page-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Client Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Order Amount</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Payment ID</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => (
                      <tr key={order.pkorderId}>
                        <th scope="row">
                          {index + 1 + (currentPage - 1) * pageSize}
                        </th>
                        <td>{order.clientName}</td>
                        <td>{order.clientEmail}</td>
                        <td>{order.clientPhNo1 || order.clientPhNo1}</td>
                        <td>{order.clientAdd1 || order.clientAdd2}</td>
                        <td>{order.orderStatus}</td>
                        <td>{order.orderAmount}</td>
                        <td>{order.orderId}</td>
                        <td>{order.paymentId || "N/A"}</td>
                        <td>
                          <button
                            type="button"
                            className="view-order-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#viewOrderModal"
                            onClick={() => handleViewOrder(order.pkorderId)}
                          >
                            <FaEye />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pagination-controls">
                  <label>
                    Show
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                    </select>
                    Orders per Page
                  </label>
                  <div className="pagination-buttons">
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      className="pagination-btn"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="loading-data">
                  <p>No Orders found.</p>
                </div>
              </>
            )} */}
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="viewOrderModal"
        tabIndex="-1"
        aria-labelledby="viewOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="viewOrderModalLabel">
                Order Detail
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {selectedOrder ? (
                  <>
                    <div
                      className="row mb-2"
                      style={{ borderBottom: "1px solid rgb(0 0 34 / 14%)" }}
                    >
                      <div className="com-12 col-sm-6 col-md-6 col-lg-6">
                        <p className="mb-1">
                          <strong>Client Name :</strong>{" "}
                          {selectedOrder.clientName}
                        </p>
                        <p className="mb-1">
                          <strong>Email :</strong> {selectedOrder.clientEmail}
                        </p>
                        <p className="mb-1">
                          <strong>Phone :</strong>{" "}
                          {selectedOrder.clientPhNo1 ||
                            selectedOrder.clientPhNo2 ||
                            "N/A"}
                        </p>
                        <p className="mb-1">
                          <strong>Address 1 :</strong>{" "}
                          {selectedOrder.clientAdd1 || "N/A"}
                        </p>
                        {/* <p>
                          <strong>Address 2 :</strong>{" "}
                          {selectedOrder.clientAdd2 || "N/A"}
                        </p> */}
                      </div>
                      <div className="com-12 col-sm-6 col-md-6 col-lg-6">
                        <p className="mb-1">
                          <strong>Order Status :</strong>{" "}
                          {selectedOrder.orderStatus}
                        </p>
                        <p className="mb-1">
                          <strong>Order Amount :</strong>{" "}
                          {selectedOrder.orderAmount}
                        </p>
                        <p className="mb-1">
                          <strong>Order ID :</strong> {selectedOrder.orderId}
                        </p>
                        <p className="mb-1">
                          <strong>Payment ID :</strong>{" "}
                          {selectedOrder.paymentId || "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* Suborder Details */}
                    <div className="">
                      <div>
                        {selectedOrder.orderDetail &&
                        selectedOrder.orderDetail.length > 0 ? (
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Product Category</th>
                                  <th>Product Price</th>
                                  <th>Product Code</th>
                                  <th>Product Name</th>
                                  <th>Custom Message</th>
                                  <th>Zodiac Sign</th>
                                  <th>Image Color</th>
                                  <th>Quantity</th>
                                  <th>Images</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedOrder.orderDetail.map((detail) => {
                                  // Assuming there could be multiple subOrderDetails
                                  return detail.subOrderDetail &&
                                    detail.subOrderDetail.length > 0 ? (
                                    detail.subOrderDetail.map(
                                      (subDetail, index) => (
                                        <tr key={index}>
                                          {/* Displaying main order details only once */}
                                          {index === 0 && (
                                            <>
                                              <td
                                                rowSpan={
                                                  detail.subOrderDetail.length
                                                }
                                              >
                                                {detail.prodCategory}
                                              </td>
                                              <td
                                                rowSpan={
                                                  detail.subOrderDetail.length
                                                }
                                              >
                                                {detail.prodPrice}
                                              </td>
                                              <td
                                                rowSpan={
                                                  detail.subOrderDetail.length
                                                }
                                              >
                                                {detail.prodCode}
                                              </td>
                                            </>
                                          )}
                                          {/* Displaying suborder details */}
                                          <td>{subDetail.productName}</td>
                                          <td>
                                            {subDetail.customMessage || "-"}
                                          </td>
                                          <td>{subDetail.zodiacSign || "-"}</td>
                                          <td>{subDetail.imageColor}</td>
                                          <td>{subDetail.quantity}</td>
                                          <td>
                                            <img
                                              src={subDetail.imagePath[0]} // Show the first image
                                              alt="suborder-img"
                                              style={{
                                                width: "60px",
                                                margin: "5px",
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan={7}>
                                        No suborders available.
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <p>No order details available.</p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No order selected.</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaEye } from "react-icons/fa";

// const Orders = () => {
//   const [orderStatusList, setOrderStatusList] = useState([]);
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(5);
//   const [totalPages, setTotalPages] = useState(0);
//   const [searchParams, setSearchParams] = useState({
//     orderId: "",
//     paymentId: "",
//     clientEmail: "",
//     orderStatus: "",
//   });
//   const [isSearchClicked, setIsSearchClicked] = useState(false);
//   const [selectedOrder, setSelectedOrder] = useState(null);

//   useEffect(() => {
//     const fetchOrderStatusList = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/order/orderStatusList`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setOrderStatusList(data);
//         } else {
//           console.error("Failed to fetch order status list");
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     };

//     fetchOrderStatusList();
//   }, []);

//   useEffect(() => {
//     if (isSearchClicked) {
//       fetchOrders();
//     }
//   }, [currentPage, pageSize]);

//   const fetchOrders = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/order/search`,
//         {
//           pkOrdID: "",
//           clientName: "",
//           clientEmail: searchParams.clientEmail,
//           clientPhNo: "",
//           clientAdd: "",
//           orderStatus: searchParams.orderStatus,
//           orderId: searchParams.orderId,
//           paymentId: searchParams.paymentId,
//           pageSize: pageSize,
//           pageNo: currentPage,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );

//       if (response.data.status === "Successful") {
//         setOrders(response.data.orderData);
//         setTotalPages(Math.ceil(response.data.totalResult / pageSize));
//         setError(null);
//       } else {
//         setOrders([]);
//         setTotalPages(0);
//         setError("No orders found");
//       }
//     } catch (error) {
//       setError("Error fetching orders");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleSearchChange = (e) => {
//     const { name, value } = e.target;
//     setSearchParams((prevParams) => ({
//       ...prevParams,
//       [name]: value,
//     }));
//   };

//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     setIsSearchClicked(true);
//     setCurrentPage(1); // Reset to first page on search
//     fetchOrders();
//   };

//   const handleViewOrder = (orderId) => {
//     const order = orders.find((order) => order.pkorderId === orderId);
//     if (order) {
//       setSelectedOrder(order);
//     }
//   };

//   if (loading) {
//     return (
//       <div className="loading-data">
//         <p>Loading...</p>
//       </div>
//     );
//   }

//   return (
//     <>
//       <section className="order-page">
//         <div className="our-container px-3">
//           <div className="inner-container">
//             <div className="order-heading mb-4">
//               <h2>Orders</h2>
//             </div>

//             {/* <div className="order-heading mb-4 d-flex align-items-center justify-content-between">
//               <div className="">
//                 <h2>Orders</h2>
//               </div>
//               <div className="order-heading d-flex align-items-center justify-content-center">
//                 <form action="">
//                   <div className="search-box-input">
//                     <input type="text" placeholder="Search..." />
//                   </div>
//                 </form>
//               </div>
//             </div> */}

//             <div className="order-page-heading mb-4 d-flex align-items-center justify-content-end mx-3">
//               <form onSubmit={handleSearchSubmit}>
//                 <div className="grid-block">
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Order Id"
//                       name="orderId"
//                       value={searchParams.orderId}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Payment Id"
//                       name="paymentId"
//                       value={searchParams.paymentId}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <input
//                       type="text"
//                       placeholder="Client Email"
//                       name="clientEmail"
//                       value={searchParams.clientEmail}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     />
//                   </div>
//                   <div className="grid-b-item">
//                     <select
//                       name="orderStatus"
//                       value={searchParams.orderStatus}
//                       onChange={handleSearchChange}
//                       autoComplete="off"
//                     >
//                       <option value="">Select Status</option>
//                       {orderStatusList.option &&
//                         orderStatusList.option.map((status) => (
//                           <option key={status} value={status}>
//                             {status}
//                           </option>
//                         ))}
//                     </select>
//                   </div>
//                 </div>
//                 <button
//                   type="submit"
//                   className="btn btn-primary order-page-btn fs-6"
//                 >
//                   Search
//                 </button>
//               </form>
//             </div>
//             {isSearchClicked && (
//               <div className="order-page-body table-responsive">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th scope="col">#</th>
//                       <th scope="col">Client Name</th>
//                       <th scope="col">Email</th>
//                       <th scope="col">Phone</th>
//                       <th scope="col">Address</th>
//                       <th scope="col">Order Status</th>
//                       <th scope="col">Order Amount</th>
//                       <th scope="col">Order ID</th>
//                       <th scope="col">Payment ID</th>
//                       <th scope="col">Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {orders.map((order, index) => (
//                       <tr key={order.pkorderId}>
//                         <th scope="row">
//                           {index + 1 + (currentPage - 1) * pageSize}
//                         </th>
//                         <td>{order.clientName}</td>
//                         <td>{order.clientEmail}</td>
//                         <td>{order.clientPhNo1 || order.clientPhNo1}</td>
//                         <td>{order.clientAdd1 || order.clientAdd2}</td>
//                         <td>{order.orderStatus}</td>
//                         <td>{order.orderAmount}</td>
//                         <td>{order.orderId}</td>
//                         <td>{order.paymentId || "N/A"}</td>
//                         <td>
//                           <button
//                             type="button"
//                             className="view-order-btn"
//                             data-bs-toggle="modal"
//                             data-bs-target="#viewOrderModal"
//                             onClick={() => handleViewOrder(order.pkorderId)}
//                           >
//                             <FaEye />
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <div className="pagination-controls">
//                   <label>
//                     Show
//                     <select
//                       value={pageSize}
//                       onChange={(e) => setPageSize(Number(e.target.value))}
//                     >
//                       <option value={5}>5</option>
//                       <option value={10}>10</option>
//                       <option value={15}>15</option>
//                     </select>
//                     Orders per Page
//                   </label>
//                   <div className="pagination-buttons">
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage - 1)}
//                       disabled={currentPage === 1}
//                     >
//                       Previous
//                     </button>
//                     <span>
//                       Page {currentPage} of {totalPages}
//                     </span>
//                     <button
//                       className="pagination-btn"
//                       onClick={() => handlePageChange(currentPage + 1)}
//                       disabled={currentPage >= totalPages}
//                     >
//                       Next
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </section>

//       <div
//         className="modal fade"
//         id="viewOrderModal"
//         tabIndex="-1"
//         aria-labelledby="viewOrderModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered modal-xl">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="viewOrderModalLabel">
//                 Order Detail
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <div className="row">
//                 {selectedOrder ? (
//                   <>
//                     <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                       <p>
//                         <strong>Client Name:</strong> {selectedOrder.clientName}
//                       </p>
//                       <p>
//                         <strong>Email:</strong> {selectedOrder.clientEmail}
//                       </p>
//                       <p>
//                         <strong>Phone:</strong>{" "}
//                         {selectedOrder.clientPhNo1 ||
//                           selectedOrder.clientPhNo2 ||
//                           "N/A"}
//                       </p>
//                       <p>
//                         <strong>Address 1:</strong>{" "}
//                         {selectedOrder.clientAdd1 || "N/A"}
//                       </p>
//                       <p>
//                         <strong>Address 2:</strong>{" "}
//                         {selectedOrder.clientAdd2 || "N/A"}
//                       </p>
//                       <p>
//                         <strong>Order Status:</strong>{" "}
//                         {selectedOrder.orderStatus}
//                       </p>
//                       <p>
//                         <strong>Order Amount:</strong>{" "}
//                         {selectedOrder.orderAmount}
//                       </p>
//                       <p>
//                         <strong>Order ID:</strong> {selectedOrder.orderId}
//                       </p>
//                       <p>
//                         <strong>Payment ID:</strong>{" "}
//                         {selectedOrder.paymentId || "N/A"}
//                       </p>
//                     </div>

//                     {/* Accordion for Suborder Details */}
//                     <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                       <div className="accordion" id="suborderAccordion">
//                         {selectedOrder.orderDetail &&
//                         selectedOrder.orderDetail.length > 0 ? (
//                           selectedOrder.orderDetail.map((detail) => (
//                             <div key={detail.pkProdId}>
//                               <h4>Product Name: {detail.prodName}</h4>
//                               <p>Product Category: {detail.prodCategory}</p>
//                               <p>Product Price: {detail.prodPrice}</p>
//                               <p>Product Code: {detail.prodCode}</p>
//                               {/* <p>Product Description: {detail.prodDesc}</p> */}

//                               <h5>Sub Order Details:</h5>
//                               {detail.subOrderDetail &&
//                               detail.subOrderDetail.length > 0 ? (
//                                 detail.subOrderDetail.map(
//                                   (subDetail, index) => (
//                                     <div key={index}>
//                                       <p>
//                                         Product Name: {subDetail.productName}
//                                       </p>
//                                       <p>Image Color: {subDetail.imageColor}</p>
//                                       <p>Quantity: {subDetail.quantity}</p>
//                                       <div>
//                                         {subDetail.imagePath.map((image, i) => (
//                                           <img
//                                             key={i}
//                                             src={image}
//                                             alt={`Sub Order ${i}`}
//                                             style={{ width: "60px" }}
//                                           />
//                                         ))}
//                                       </div>
//                                     </div>
//                                   )
//                                 )
//                               ) : (
//                                 <p>No suborders available.</p>
//                               )}
//                             </div>
//                           ))
//                         ) : (
//                           <p>No order details available.</p>
//                         )}
//                       </div>
//                     </div>
//                   </>
//                 ) : (
//                   <p>No order selected.</p>
//                 )}
//                 {/* <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                   <div className="mb-3">
//                     <label htmlFor="clientName" className="form-label">
//                       Client Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="clientName"
//                       value={selectedOrder?.clientName || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="clientEmail" className="form-label">
//                       Client Email
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="clientEmail"
//                       value={selectedOrder?.clientEmail || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="clientPhNo1" className="form-label">
//                       Client Phone
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="clientPhNo1"
//                       value={selectedOrder?.clientPhNo1 || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="clientAdd1" className="form-label">
//                       Address
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="clientAdd1"
//                       value={selectedOrder?.clientAdd1 || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="city" className="form-label">
//                       City
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="city"
//                       value={selectedOrder?.city || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="state" className="form-label">
//                       State
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="state"
//                       value={selectedOrder?.state || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="orderAmount" className="form-label">
//                       Order Amount
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="orderAmount"
//                       value={selectedOrder?.orderAmount || ""}
//                       readOnly
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="orderStatus" className="form-label">
//                       Order Status
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="orderStatus"
//                       value={selectedOrder?.orderStatus || ""}
//                       readOnly
//                     />
//                   </div>
//                 </div>
//                 <div className="com-12 col-sm-6 col-md-6 col-lg-6">
//                   sub order details show
//                 </div> */}
//               </div>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 data-bs-dismiss="modal"
//               >
//                 Close
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Orders;
