import React, { useEffect, useState } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProductCategory = () => {
  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [editProductCategory, setEditProductCategory] = useState({
    id: null,
    prodCategory: "",
    isActive: false,
  });
  const [newProductCategory, setNewProductCategory] = useState({
    prodCategory: "",
    isActive: false,
  });

  const fetchProductCategory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/prodCategory/search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            pkProdCategoryId: "",
            prodCategory: "",
            isActive: "",
            pageSize: 10,
            pageNo: 1,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "Successful") {
        setProductCategories(data.data);
      } else {
        console.error("Failed to fetch product categories:", data.message);
      }
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  };

  // Fetch product category on component mount
  useEffect(() => {
    fetchProductCategory();
  }, []);

  const handleEditClick = (index) => {
    const selectedProductCategory = productCategories[index];
    setEditProductCategory({
      id: selectedProductCategory.pkProdCategoryId,
      prodCategory: selectedProductCategory.productCategory,
      isActive: selectedProductCategory.isActive === "1",
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditProductCategory((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNewProductCategoryChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProductCategory((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/prodCategory/update/${editProductCategory.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            prodCategory: editProductCategory.prodCategory,
            isActive: editProductCategory.isActive ? "1" : "0",
          }),
        }
      );

      const data = await response.json();
      if (data.status === "Successful") {
        setProductCategories((prev) =>
          prev.map((type) =>
            type.pkProdCategoryId === editProductCategory.id
              ? {
                  ...type,
                  productCategory: editProductCategory.prodCategory,
                  isActive: editProductCategory.isActive ? "1" : "0",
                }
              : type
          )
        );

        toast.success("Update successfully!", {
          autoClose: 1000,
          position: "bottom-right",
        });

        // Close the edit modal programmatically
        const editModal = window.bootstrap.Modal.getInstance(
          document.getElementById("exampleModalEdit")
        );
        if (editModal) editModal.hide();
      } else {
        console.error("Failed to update product category:", data.message);
      }
    } catch (error) {
      console.error("Error updating product category:", error);
    }
  };

  const handleNewProductCategorySubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/prodCategory/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            prodCategory: newProductCategory.prodCategory,
            isActive: newProductCategory.isActive ? "1" : "0",
          }),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setProductCategories((prev) => [
          ...prev,
          { ...newProductCategory, pkProdCategoryId: data.newId },
        ]);

        toast.success("Product category added!!", {
          autoClose: 1000,
          position: "bottom-right",
        });

        // // Close the add modal programmatically
        // const addModal = window.bootstrap.Modal.getInstance(
        //   document.getElementById("exampleModalAdd")
        // );
        // if (addModal) addModal.hide();

        // Close the modal
        const modal = document.getElementById("exampleModalAdd");
        const modalBackdrop = document.querySelector(".modal-backdrop");
        modal.classList.remove("show");
        modal.style.display = "none";
        modalBackdrop && modalBackdrop.remove();

        setNewProductCategory({ prodCategory: "", isActive: false });

        fetchProductCategory();
      } else {
        console.error("Failed to add product category:", data.message);
      }
    } catch (error) {
      console.error("Error adding product category:", error);
    }
  };

  const handleDeleteClick = async (pkProdCategoryId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product category?"
    );

    if (confirmDelete) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prodCategory/delete/${pkProdCategoryId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await response.json();
        if (data.status === "Successful") {
          setProductCategories((prev) =>
            prev.filter((type) => type.pkProdCategoryId !== pkProdCategoryId)
          );
        } else {
          console.error("Failed to delete product category:", data.message);
        }
      } catch (error) {
        console.error("Error deleting product category:", error);
      }
    }
  };

  return (
    <>
      <section className="product-category-page">
        <div className="our-container px-3">
          <div className="inner-container">
            <div className="product-category-page-heading mb-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h2>Product Category</h2>
              </div>
              <div className="product-category-heading d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn product-category-page-heading-add-link"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalAdd"
                >
                  <FaPlus className="plus-icon-add" />
                  <span>Add</span>
                </button>
              </div>
            </div>
            {productCategories.length > 0 ? (
              <div className="product-category-page-body table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Product Category</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productCategories.length > 0 ? (
                      productCategories.map((productCategory, index) => (
                        <tr key={productCategory.pkProdCategoryId}>
                          <td>{index + 1}</td>
                          <td>{productCategory.productCategory}</td>
                          <td>
                            <button
                              type="button"
                              className="btn action-icon-edit-link"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalEdit"
                              onClick={() => handleEditClick(index)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="action-icon-delete-btn"
                              onClick={() =>
                                handleDeleteClick(
                                  productCategory.pkProdCategoryId
                                )
                              }
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No product categories available.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <div className="loading-data">
                  <p>No Category found.</p>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {/* Add Product Category Modal */}
      <div
        className="modal fade product-category-modal"
        id="exampleModalAdd"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Product Category
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalBtnAdd"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleNewProductCategorySubmit}>
                <div className="d-flex align-items-center flex-column mb-4 product-category-form-group">
                  <input
                    type="text"
                    id="newProdCategory"
                    name="prodCategory"
                    value={newProductCategory.prodCategory}
                    onChange={handleNewProductCategoryChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Product Type"
                  />
                </div>
                <div className="d-flex flex-column mb-4 product-category-form-group">
                  <label htmlFor="newIsActive">Is Active</label>
                  <input
                    type="checkbox"
                    id="newIsActive"
                    name="isActive"
                    checked={newProductCategory.isActive}
                    onChange={handleNewProductCategoryChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    style={{ width: "24px" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-capitalize product-category-form-button"
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Product Category Modal */}
      <div
        className="modal fade product-category-modal"
        id="exampleModalEdit"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Product Category
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModalBtnEdit"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleFormSubmit}>
                <div className="d-flex align-items-center flex-column mb-4 product-category-form-group">
                  <input
                    type="text"
                    id="prodCategory"
                    name="prodCategory"
                    value={editProductCategory.prodCategory}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    placeholder="Your Product Type"
                  />
                </div>
                <div className="d-flex flex-column mb-4 product-category-form-group">
                  <label htmlFor="isActive">Is Active</label>
                  <input
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    checked={editProductCategory.isActive}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="px-2 rounded-0"
                    style={{ width: "24px" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-capitalize product-category-form-button"
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCategory;

// import React, { useEffect, useState } from "react";
// import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

// const ProductCategory = () => {
//   const navigate = useNavigate();

//   const [productCategories, setProductCategories] = useState([]);
//   const [editProductCategory, setEditProductCategory] = useState({
//     id: null,
//     prodCategory: "",
//     isActive: false,
//   });
//   const [newProductCategory, setNewProductCategory] = useState({
//     prodCategory: "",
//     isActive: false,
//   });

//   // Fetch product category on component mount
//   useEffect(() => {
//     const fetchProductCategory = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/prodCategory/search`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//             body: JSON.stringify({
//               pkProdCategoryId: "",
//               prodCatory: "",
//               isActive: "",
//               pageSize: 10,
//               pageNo: 1,
//             }),
//           }
//         );
//         const data = await response.json();
//         if (data.status === "Successful") {
//           setProductCategories(data.data);
//         } else {
//           console.error("Failed to fetch product types:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching product types:", error);
//       }
//     };

//     fetchProductCategory();
//   }, []);

//   const handleEditClick = (index) => {
//     const selectedProductCategory = productCategories[index];
//     setEditProductCategory({
//       id: selectedProductCategory.pkProdCategoryId,
//       prodType: selectedProductCategory.productCategory,
//       isActive: selectedProductCategory.isActive === "1",
//     });
//   };

//   const handleInputChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setEditProductCategory((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleNewProductCategoryChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setNewProductCategory((prev) => ({
//       ...prev,
//       [name]: type === "checkbox" ? checked : value,
//     }));
//   };

//   const handleFormSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/prodCategory/update/${editProductCategory.id}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//           body: JSON.stringify({
//             prodCategory: editProductCategory.prodCategory,
//             isActive: editProductCategory.isActive ? "1" : "0",
//           }),
//         }
//       );

//       const data = await response.json();
//       if (data.status === "Successful") {
//         setProductCategories((prev) =>
//           prev.map((type) =>
//             type.pkProdCategoryId === editProductCategory.id
//               ? {
//                   ...type,
//                   productCategory: editProductCategory.prodType,
//                   isActive: editProductCategory.isActive ? "1" : "0",
//                 }
//               : type
//           )
//         );
//         navigate("/");
//         // Close the edit modal programmatically
//         const editModal = new window.bootstrap.Modal(
//           document.getElementById("exampleModalEdit")
//         );
//         editModal.hide();
//       } else {
//         console.error("Failed to update product type:", data.message);
//       }
//     } catch (error) {
//       console.error("Error updating product type:", error);
//     }
//   };

//   const handleNewProductCategorySubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}/prodCategory/add`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//           body: JSON.stringify({
//             prodCategory: newProductCategory.prodCategory,
//             isActive: newProductCategory.isActive ? "1" : "0",
//           }),
//         }
//       );

//       const data = await response.json();
//       if (data.status === "Successful") {
//         setProductCategories((prev) => [
//           ...prev,
//           { ...newProductCategory, pkProdCategoryId: data.newId },
//         ]);
//         navigate("/");
//         // Close the add modal programmatically
//         const addModal = new window.bootstrap.Modal(
//           document.getElementById("exampleModalAdd")
//         );
//         addModal.hide();
//         setNewProductCategory({ prodCategory: "", isActive: false });
//       } else {
//         console.error("Failed to add product category:", data.message);
//       }
//     } catch (error) {
//       console.error("Error adding product category:", error);
//     }
//   };

//   const handleDeleteClick = async (pkProdCategoryId) => {
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this product type?"
//     );

//     if (confirmDelete) {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/prodCategory/delete/${pkProdCategoryId}`,
//           {
//             method: "DELETE",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//           }
//         );

//         const data = await response.json();
//         if (data.status === "Successful") {
//           setProductCategories((prev) =>
//             prev.filter((type) => type.pkProdCategoryId !== pkProdCategoryId)
//           );
//         } else {
//           console.error("Failed to delete product type:", data.message);
//         }
//       } catch (error) {
//         console.error("Error deleting product type:", error);
//       }
//     }
//   };

//   return (
//     <>
//       <section className="product-category-page">
//         <div className="our-container px-3">
//           <div className="inner-container">
//             <div className="product-category-page-heading mb-4 d-flex align-items-center justify-content-between">
//               <div className="">
//                 <h2>Product Category</h2>
//               </div>
//               <div className="product-category-heading d-flex align-items-center justify-content-center">
//                 <button
//                   type="button"
//                   className="btn product-category-page-heading-add-link"
//                   data-bs-toggle="modal"
//                   data-bs-target="#exampleModalAdd"
//                 >
//                   <FaPlus className="plus-icon-add" />
//                   <span>Add</span>
//                 </button>
//               </div>
//             </div>
//             <div className="product-category-page-body table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">#</th>
//                     <th scope="col">Product Category</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {productCategories.length > 0 ? (
//                     productCategories.map((productCategory, index) => (
//                       <tr key={productCategory.pkProdCategoryId}>
//                         <td>{index + 1}</td>
//                         <td>{productCategory.productCategory}</td>
//                         <td>
//                           <button
//                             type="button"
//                             className="btn action-icon-edit-link"
//                             data-bs-toggle="modal"
//                             data-bs-target="#exampleModalEdit"
//                             onClick={() => handleEditClick(index)}
//                           >
//                             <FaEdit />
//                           </button>
//                           <button
//                             className="action-icon-delete-btn"
//                             onClick={() =>
//                               handleDeleteClick(
//                                 productCategory.pkProdCategoryId
//                               )
//                             }
//                           >
//                             <FaTrash />
//                           </button>
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <tr>
//                       <td colSpan="3">No product types available.</td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Add Product Category Modal */}
//       <div
//         className="modal fade product-category-modal"
//         id="exampleModalAdd"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Add Product Category
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 id="closeModalBtnAdd"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleNewProductCategorySubmit}>
//                 <div className="d-flex align-items-center flex-column mb-4 product-category-form-group">
//                   <input
//                     type="text"
//                     id="newProdCategory"
//                     name="prodCategory"
//                     value={newProductCategory.prodCategory}
//                     onChange={handleNewProductCategoryChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your Product Type"
//                   />
//                 </div>
//                 <div className="d-flex flex-column mb-4 product-category-form-group">
//                   <label htmlFor="newIsActive">Is Active</label>
//                   <input
//                     type="checkbox"
//                     id="newIsActive"
//                     name="isActive"
//                     checked={newProductCategory.isActive}
//                     onChange={handleNewProductCategoryChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     style={{ width: "24px" }}
//                   />
//                 </div>
//                 <button
//                   type="submit"
//                   className="text-capitalize product-category-form-button"
//                 >
//                   Add
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Edit Product Type Modal */}
//       <div
//         className="modal fade product-category-modal"
//         id="exampleModalEdit"
//         tabIndex="-1"
//         aria-labelledby="exampleModalLabel"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h1 className="modal-title fs-5" id="exampleModalLabel">
//                 Edit Product Category
//               </h1>
//               <button
//                 type="button"
//                 className="btn-close"
//                 data-bs-dismiss="modal"
//                 aria-label="Close"
//                 id="closeModalBtnEdit"
//               ></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleFormSubmit}>
//                 <div className="d-flex align-items-center flex-column mb-4 product-category-form-group">
//                   <input
//                     type="text"
//                     id="prodCategory"
//                     name="prodCategory"
//                     value={editProductCategory.prodCategory}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     placeholder="Your Product Type"
//                   />
//                 </div>
//                 <div className="d-flex flex-column mb-4 product-category-form-group">
//                   <label htmlFor="isActive">Is Active</label>
//                   <input
//                     type="checkbox"
//                     id="isActive"
//                     name="isActive"
//                     checked={editProductCategory.isActive}
//                     onChange={handleInputChange}
//                     autoComplete="off"
//                     className="px-2 rounded-0"
//                     style={{ width: "24px" }}
//                   />
//                 </div>
//                 <button
//                   type="submit"
//                   className="text-capitalize product-category-form-button"
//                 >
//                   Update
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProductCategory;
