import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthContext";

const Header = () => {
  const { signOut, user } = useContext(AuthContext);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <header className={isFixed ? "fixed-header" : ""}> */}
      <header>
        <div className="py-1 header-top"></div>
        <div className="upper-menu-block">
          <div className="our-container">
            <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-5">
              <div className="logo-block my-2">
                <Link to={"/"} className="d-block">
                  <img src="/images/karv-logo.png" alt="" />
                </Link>
              </div>
              <div className="main-menu">
                <nav>
                  <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
                    <li>
                      <NavLink to={"/"} className="fs-6 fw-semibold nav-items">
                        Dashboard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to={"/b2b"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        B2B
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to={"/product-category"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product Category
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/product-type"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Product Type
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/orders"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Orders
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/paid-orders"}
                        className="fs-6 fw-semibold nav-items"
                      >
                        Paid Orders
                      </NavLink>
                    </li>
                    <li>
                      <div class="dropdown">
                        <button
                          class=" btn-secondary dropdown-toggle nav-link text-black fs-6 fw-semibold nav-items d-flex align-items-center gap-2 myBTN"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FaUserCircle className="fa-user-icon fs-4" />
                          <p className="mb-0">{user?.userName}</p>
                        </button>
                        <ul class="dropdown-menu nav-links">
                          <li className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn nav-link text-black fs-6 fw-semibold nav-items d-flex align-items-center gap-2"
                              type="button"
                              onClick={() => {
                                signOut();
                              }}
                            >
                              <FiLogOut className="fa-user-icon" />
                              <p className="mb-0"> Sign Out</p>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* <li>
                      <div className="">
                        <button
                          className="btn nav-link text-black fs-6 fw-semibold nav-items d-flex align-items-center gap-2"
                          type="button"
                        >
                          <FaUserCircle className="fa-user-icon fs-4" /> 
                          <p className="mb-0">{user?.userName}</p>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown">
                        <button
                          className="btn nav-link text-black fs-6 fw-semibold nav-items"
                          type="button"
                          onClick={() => {
                            signOut();
                          }}
                        >
                          <FiLogOut className="fa-user-icon" />
                        </button>
                      </div>
                    </li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
